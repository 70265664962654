export enum AdminOnlySettings {
  BillingSettings = 'BillingSettings',
  CareTeamSharingSettings = 'CareTeamSharingSettings',
  LabSettings = 'LabSettings',
  LabOrdersAutomationSettings = 'LabOrdersAutomationSettings',
  LabOrdersSettings = 'LabOrdersSettings',
  MembershipManagementSettings = 'MembershipManagementSettings',
  PatientFormSettings = 'PatientFormSettings',
  PracticeLocationsSettings = 'PracticeLocationsSettings',
  PracticeStripeAccountSettings = 'PracticeStripeAccountSettings',
  PrescriptionSettings = 'PrescriptionSettings',
  TelehealthSettings = 'TelehealthSettings',
  TemplateSettings = 'TemplateSettings',
  SecurityPrivacySettings = 'SecurityPrivacySettings',
  SortSettings = 'SortSettings',
}
