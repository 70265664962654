import { Dialog } from '@el8/vital';
import React, { useCallback, useEffect } from 'react';
import {
  getGetTrialQueryKey,
  Trial,
  TrialState,
  useEnableTrial,
  useGetTrial,
} from '@elation/api-client/src/feature-trial';
import { useQueryClient } from '@tanstack/react-query';
import UserFacingError from 'utils/errors/UserFacingError';
import { toastError } from 'utils/errors';
import { TrialActivationKey } from 'ui-modules/trial-activation/TrialActivationTypes';
import {
  logClickOffer,
  logCloseConfirmation,
  logCloseOffer,
  logViewConfirmation,
  logViewOffer,
} from './amplitudeHelpers';
import { trialActivationViews } from './trialActivationViewMap';

export type TrialViewProps = {
  onActivate: () => void;
  onClose: () => void;
  currentStatus?: Trial;
};

type Props = {
  trialName: TrialActivationKey;
  isOpen: boolean;
  onClose: () => void;
};

const statusToView = (
  trialName: TrialActivationKey,
  status?: TrialState,
): React.FC<TrialViewProps> | null => {
  if (!status) return null;
  if (trialActivationViews[trialName]) {
    const {
      OfferView = null,
      ActivatedView = null,
      ErrorView = null,
    } = trialActivationViews[trialName] || {};
    const trialMap = {
      [TrialState.NOT_STARTED]: OfferView,
      [TrialState.ACTIVE]: ActivatedView,
      [TrialState.NOT_ELIGIBLE]: ErrorView,
      // FUTURE: these may need more view props TBD
      [TrialState.PURCHASED]: ErrorView,
      [TrialState.EXPIRED]: ErrorView,
    };
    return trialMap[status] || null;
  }
  console.error(`Trial views for feature:${trialName} not found`);
  return null;
};

const showToastError = (message: string): void => {
  toastError(new UserFacingError(message));
};

const showStateError = (state: TrialState): void => {
  switch (state) {
    case TrialState.NOT_STARTED:
      showToastError('Unable to start trial');
      break;
    case TrialState.NOT_ELIGIBLE:
      showToastError('You are not eligible for this trial');
      break;
    case TrialState.EXPIRED:
      showToastError('Your trial has expired');
      break;
    default:
      break;
  }
};

export const TrialActivationDialog: React.FC<Props> = ({ trialName, isOpen, onClose }) => {
  const { data } = useGetTrial(trialName, {
    query: {
      enabled: isOpen,
    },
  });
  const queryClient = useQueryClient();
  const { mutateAsync: activate } = useEnableTrial({
    mutation: {
      onSuccess: (newState) => {
        queryClient.setQueryData(getGetTrialQueryKey(trialName), newState);
        // Handle 200 responses with bad states
        showStateError(newState.state);
      },
      onError: (error) => {
        // Handle auth and server errors
        showToastError(error.response?.data.message ?? error.message);
      },
    },
  });

  const handleActivate = useCallback(async () => {
    logClickOffer(trialName);
    await activate({ trialName });
  }, [activate, trialName]);

  useEffect(() => {
    if (isOpen) {
      if (data?.state === TrialState.NOT_STARTED) {
        logViewOffer(trialName);
      } else if (data?.state === TrialState.ACTIVE) {
        logViewConfirmation(trialName);
      }
    } else if (data?.state === TrialState.NOT_STARTED) {
      logCloseOffer(trialName);
    } else if (data?.state === TrialState.ACTIVE) {
      logCloseConfirmation(trialName);
    }
  }, [data?.state, trialName, isOpen]);

  const CurrentView = statusToView(trialName, data?.state);

  if (!data || CurrentView === null) {
    return null;
  }

  return (
    <Dialog padded isOpen={isOpen} hasDividers={false}>
      <CurrentView onClose={onClose} onActivate={handleActivate} currentStatus={data} />
    </Dialog>
  );
};

export default TrialActivationDialog;
