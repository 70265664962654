import { logEvent } from 'utils/amplitude';

export const logViewOffer = (trialName: string): void =>
  logEvent('Purchase - View in-app purchase feature overview', { 'Trial Viewed': trialName });

export const logCloseOffer = (trialName: string): void =>
  logEvent('Purchase - Close in-app purchase feature overview', { 'Trial Viewed': trialName });

export const logClickOffer = (trialName: string): void =>
  logEvent('Purchase - Click call-to-action in in-app purchase experience', {
    'Trial Viewed': trialName,
    'Call-to-action': 'Activate trial',
  });

export const logViewConfirmation = (trialName: string): void =>
  logEvent('Purchase - View call-to-action confirmation', {
    'Trial Viewed': trialName,
    'Call-to-action': 'Activate trial',
  });

export const logCloseConfirmation = (trialName: string): void =>
  logEvent('Purchase - Close call-to-action confirmation', {
    'Trial Viewed': trialName,
    'Call-to-action': 'Activate trial',
  });

export const logOpenTrialDialog = (trialName: string, source: string): void =>
  logEvent('Purchase - Click link to open in-app purchase experience', {
    source,
    'Trial Viewed': trialName,
  });

export const logLearnMore = (trialName: string, source: string, description: string): void =>
  logEvent(`Purchase - ${description}`, {
    Source: source,
    'Trial Viewed': trialName,
  });
