export enum TrialActivationKey {
  ElationAI = 'ElationAI',
}
export interface OpenTrialActivationDialogPayload {
  trialName: TrialActivationKey;
}

export interface TrialActivationDialogState extends OpenTrialActivationDialogPayload {
  isOpen: true;
}
