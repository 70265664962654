import React from 'react';
import { Button2, DialogCloseButton, HeaderText, Stack, Text2 } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { TrialViewProps } from '../TrialActivationDialog';

export const DefaultErrorView = ({ onClose }: TrialViewProps): JSX.Element => {
  return (
    <Stack>
      <Flexbox justify="space-between" align="center">
        <HeaderText tag="h1">Something Went Wrong</HeaderText>
        <DialogCloseButton onClick={onClose} />
      </Flexbox>
      <Text2>
        We are sorry, but something went wrong with your request. Please try again later or contact
        support if the issue persists.
      </Text2>
      <Button2 label="Close" onClick={onClose} />
    </Stack>
  );
};
