import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OpenTrialActivationDialogPayload,
  TrialActivationDialogState,
} from './TrialActivationTypes';

export interface TrialActivationSlice {
  trialActivation: { isOpen: false } | TrialActivationDialogState;
}

export const trialActivationInitialState: TrialActivationSlice = {
  trialActivation: {
    isOpen: false,
  },
};

const trialActivationSlice = createSlice({
  name: 'TrialActivation',
  initialState: trialActivationInitialState,
  reducers: {
    openTrialDialog(state, action: PayloadAction<OpenTrialActivationDialogPayload>): void {
      state.trialActivation = {
        isOpen: true,
        trialName: action.payload.trialName,
      };
    },
    closeTrialDialog(state): void {
      state.trialActivation = {
        isOpen: false,
      };
    },
  },
});

const { actions, reducer: trialActivationReducer } = trialActivationSlice;
const { openTrialDialog, closeTrialDialog } = actions;

export { trialActivationReducer, openTrialDialog, closeTrialDialog };
