import isString from 'lodash/isString';
import has from 'lodash/has';
import { DEFAULT_ERROR_MESSAGE } from 'utils/alert';

import { ResponseError } from '.';
import UserFacingError from './UserFacingError';

/**
 * Accepts an error object and decides what text to show to the user in an alert,
 * based on the type of the error object. This should be preferred for consistent
 * error messaging that is aware of what should and should not be shown to the
 * user.
 *
 * For all non-custom Errors, this function defaults to whatever generic message
 * utils/alert defaults to.
 * For custom Errors, it will attempt to determine what non-generic message it can
 * show the user to be more helpful.
 *
 * @param error - an error object. Can be a built-in Error or custom Error.
 */
export default function getErrorMessage(
  error: Error,
  defaultMessage: string = DEFAULT_ERROR_MESSAGE,
): string {
  let message = defaultMessage;
  if (error instanceof UserFacingError) {
    ({ message } = error);
  } else if (error instanceof ResponseError) {
    // Conflict error; our API typically returns user-helpful messages for these
    if (error.status === 409) {
      if (has(error, ['body', 'message']) && isString(error.body.message)) {
        // eslint-disable-next-line prefer-destructuring
        message = error.body.message;
      } else if (error.text) {
        message = error.text;
      }
    }

    const standardizedErrors = error.body?.errors;
    if (Array.isArray(standardizedErrors)) {
      message = standardizedErrors.map((err) => err.message).join('\n');
    }
  }
  return message;
}
