import { TrialActivationKey } from 'ui-modules/trial-activation/TrialActivationTypes';
import { NoteAssistOfferView } from './trial-views/note-assist/NoteAssistOfferView';
import { NoteAssistActivatedView } from './trial-views/note-assist/NoteAssistActivatedView';
import { DefaultErrorView } from './trial-views/DefaultErrorView';
import { TrialViewProps } from './TrialActivationDialog';

export type TrialActivationViewConfig = {
  OfferView: React.FC<TrialViewProps>;
  ActivatedView: React.FC<TrialViewProps>;
  ErrorView: React.FC<TrialViewProps>;
};

export const trialActivationViews: { [key in TrialActivationKey]: TrialActivationViewConfig } = {
  [TrialActivationKey.ElationAI]: {
    OfferView: NoteAssistOfferView,
    ActivatedView: NoteAssistActivatedView,
    ErrorView: DefaultErrorView,
  },
};
