import React from 'react';
import Button from 'widgets/Button';
import styles from './AlertPopup.less';

export default class Alert extends React.Component {
  state = { isOpen: false, title: 'ALERT', content: null };

  componentDidUpdate(_prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      this.buttonWrapper.children[0].focus();
    }
  }

  /**
   * @public
   * This is called from `utils/alert` to provide easy access to alerts.
   */
  onShow = (content, title = 'ALERT') => {
    this.setState({ isOpen: true, title, content });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen, title, content } = this.state;

    return (
      <div className={styles.overlay} style={{ display: isOpen ? 'block' : 'none' }}>
        <div className={styles.container}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.content} data-dd-privacy="allow">
            {content}
          </div>
          <div className={styles.buttons}>
            {/* Wraps the button to give us a ref target, because we can't give
                Button a ref due to it being a functional component. */}
            <div
              ref={(div) => {
                this.buttonWrapper = div;
              }}
            >
              <Button kind="primary" onClick={this.onClose} style={{ width: 50 }}>
                OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
