import React from 'react';
import { useCloseTrialActivationDialog } from 'ui-modules/trial-activation';
import { useAppSelector } from 'utils/hooks';
import TrialActivationDialog from 'views/shared/trial-activation/TrialActivationDialog';

export const TrialDialog = (): JSX.Element | null => {
  const closeTrialActivationDialog = useCloseTrialActivationDialog();
  const trialState = useAppSelector((state) => state?.trialActivation?.trialActivation);
  return trialState?.isOpen ? (
    <TrialActivationDialog {...trialState} onClose={closeTrialActivationDialog} />
  ) : null;
};
