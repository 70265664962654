import React from 'react';
import ReactDOM from 'react-dom';
import { SentryErrorBoundary } from 'utils/sentry';
import Alert from './AlertPopup';
import NewAlert from './NewAlertPopup';

export const DEFAULT_ERROR_MESSAGE =
  'An error has occurred. Please refresh the page. If this persists, ' +
  'contact Support using the "I need help" menu above. ' +
  `Please include the following error code with any ticket or question: ${el8Globals.PAGE_SESSION_ID}`;

/**
 * A custom error class that represents an error displayed to the user
 * in a form of an alert dialog.
 */
class UserAlertError extends Error {}

type AlertComponent = React.Component & {
  onShow: (content: React.ReactNode, title?: string, onClose?: () => void) => void;
};

let alerter: AlertComponent | null = null;

/**
 * Initializes the alert component used throughout the app.
 * `alert` calls will not work until this function is called.
 */
export function initAlert(useNew = false): void {
  const container = document.getElementById('react-alert-container');
  if (!container) {
    console.warn('Cannot initialize alert dialog without a #react-alert-container.');
    return;
  }

  const Component = useNew ? NewAlert : Alert;

  const alertComponent = React.createElement(Component, {
    ref: (component: AlertComponent): void => {
      alerter = component;
    },
  });
  ReactDOM.render(<SentryErrorBoundary>{alertComponent}</SentryErrorBoundary>, container);
}

/**
 * Displays a modal alert dialog.
 *
 * @param content - the text content of the dialog
 * @param [title] - the title of the dialog. Defaults to 'ALERT'
 * @param [onClose] - optional callback to be invoked when alert closes.
 */
export default function alert(
  content: React.ReactNode = DEFAULT_ERROR_MESSAGE,
  title?: string,
  onClose?: () => void,
): void {
  if (!alerter) {
    console.warn('Alert dialog not initialized.');
    return;
  }

  try {
    if (typeof content === 'string') {
      window.DD_RUM?.addError(new UserAlertError(content));
    }
  } catch (err) {
    console.warn(err);
  }

  alerter.onShow(content, title, onClose);
}
