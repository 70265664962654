import React from 'react';
import { DialogCloseButton, HeaderText, Stack, Text2 } from '@el8/vital';
import { Flexbox } from 'components/layout';
import { TrialViewProps } from '../../TrialActivationDialog';
import styles from './NoteAssistActivatedView.less';

export const NoteAssistActivatedView = ({ onClose }: TrialViewProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <Flexbox justify="space-between" className={styles.header}>
        <HeaderText tag="h1">
          Your free trial of <strong>Note Assist</strong> is active - start using it today!
        </HeaderText>
        <DialogCloseButton onClick={onClose} />
      </Flexbox>
      <br />
      <Stack className={styles.content}>
        <Text2>
          <strong>Note Assist</strong> is Elation’s AI-powered medical scribing and note generation
          solution, accessed from the &quot;Scribe&quot; button within the visit note workflow.
          Watch our short video below and review our{' '}
          <a
            href="https://help.elationhealth.com/s/article/Note-Assist-Guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            Note&nbsp;Assist&nbsp;guide
          </a>{' '}
          for helpful instructions and tips.
        </Text2>
        <Text2>
          Benefits of <strong>Note Assist</strong>:
          <ul className={styles.list}>
            <li>
              Saves up to <strong>two hours</strong> daily
            </li>
            <li>Seamlessly embedded in your workflow</li>
            <li>
              Crafts accurate, high-quality notes using chart context and existing note content
            </li>
            <li>Works with Elation note templates</li>
          </ul>
        </Text2>
        {/* FUTURE: add caption support */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video controls width={600} className={styles.video}>
          <source src="/static/images/video/note_assist_in-app_trial_v2.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
        <Text2>
          This free trial offer will automatically expire after 5 days. At the end of the free trial
          period, Elation will contact you to discuss next steps. There is no obligation to
          purchase.
        </Text2>
        <Text2>
          Please refresh the page, if needed, to see the orange &quot;Scribe&quot; button. For
          technical support, contact Elation Support using the in-app &quot;I need help&quot; menu
          on the Elation toolbar.
        </Text2>
      </Stack>
    </div>
  );
};
